import { encrypt } from 'ecies-geth';

/**
 * Encrypt data using a public key in secp256k1 format.
 * 
 * @param {string} publicKeyHex - The public key in hexadecimal format.
 * @param {string} data - The plaintext data to encrypt.
 * @returns {Promise<string>} - The encrypted data encoded in base64.
 */
const encryptWithPublicKey = async (publicKey, data) => {
    try {
        const publicKeyBuffer = Buffer.from(publicKey, 'hex');
        const dataBuffer = Buffer.from(data, 'utf8');
        const encrypted = await encrypt(publicKeyBuffer, dataBuffer);
        return encrypted.toString('base64');
    } catch (error) {
        console.error('Encryption error:', error);
        throw new Error('Encryption failed');
    }
};

export default encryptWithPublicKey;
